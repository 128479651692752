import React, { Fragment, useEffect, useState } from "react";
import "./newProduct.css";
import { useSelector, useDispatch } from "react-redux";
import { clearErrors, createDesign } from "../../actions/designAction.js";
import { useAlert } from "react-alert";
import { Button } from "@material-ui/core";
import MetaData from "../layout/MetaData";
import SideBar from "./Sidebar";
import { NEW_DESIGN_RESET } from "../../constants/designConstants.js";
import { useNavigate } from "react-router-dom";
import AccountTreeIcon from "@material-ui/icons/AccountTree";

const NewDesign = ({ history }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const alert = useAlert();

  const { loading, error, success } = useSelector((state) => state.newDesign);

  const [images, setImages] = useState(null);
  const [imagesPreview, setImagesPreview] = useState(null);
  const [category, setCategory] = useState("");

  const categories = [
    "Home Furniture Design",
    "Military Furniture Design",
    "HealthCare Furniture Design",
    "Chairs Design",
    "Sofa Designs",
    "Steel Locker Design",
    "Almirah Design",
    "Side Tables",
  ];

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (success) {
      alert.success("Design Created Successfully");
      navigate("/admin/dashboard");
      dispatch({ type: NEW_DESIGN_RESET });
    }
  }, [dispatch, alert, error, history, success]);

  const createDesignSubmitHandler = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    // Check if both category and image are set
    if (category && images) {
      myForm.append("category", category); // Correctly append category
      myForm.append("image", images); // Append image file (note: should match backend field name)
    } else {
      alert.error(
        "Please select both a category and an image before submitting."
      );
      return;
    }
    console.log(myForm);

    dispatch(createDesign(myForm));
  };

  const createDesignImagesChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImages(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagesPreview(reader.result); // Set the preview URL
      };
      reader.readAsDataURL(file);
    } else {
      setImages(null);
      setImagesPreview(null);
    }
  };

  return (
    <Fragment>
      <MetaData title="Create Design" />
      <div className="dashboard mt-[84px]">
        <SideBar />
        <div className="newProductContainer">
          <div className="createProductForm">
            <h1>Create Design</h1>

            <div>
              <AccountTreeIcon />
              <select onChange={(e) => setCategory(e.target.value)}>
                <option value="">Choose Category</option>
                {categories.map((cate) => (
                  <option key={cate} value={cate}>
                    {cate}
                  </option>
                ))}
              </select>
            </div>

            <div id="createProductFormFile">
              <input
                type="file"
                name="avatar"
                accept="image/*"
                onChange={createDesignImagesChange}
                multiple
              />
            </div>
            {imagesPreview && (
              <div>
                <img src={imagesPreview} alt="Design Preview" />
              </div>
            )}

            <Button
              id="createProductBtn"
              type="submit"
              disabled={loading ? true : false}
              onClick={createDesignSubmitHandler}
            >
              Create
            </Button>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default NewDesign;
