import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "./logo-v2.png";
import "./Header.css";
import { FaSearch, FaShoppingCart, FaSignInAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import HomeIcon from "@mui/icons-material/Home";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import BuildIcon from "@mui/icons-material/Build";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import InfoIcon from "@mui/icons-material/Info";

const Navbar = () => {
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [hidden, setHidden] = useState(false);
  const { isAuthenticated } = useSelector((state) => state.user);

  // Handle scroll events
  useEffect(() => {
    const handleScroll = () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (scrollTop > lastScrollTop) {
        setHidden(true);
      } else {
        setHidden(false);
      }
      setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop]);

  return (
    <div>
      <div className={`navbar ${hidden ? "hidden" : ""}`} id="navbar">
        <div className="flex-shrink-0">
          <Link
            to="/"
            // className="text-2xl font-bold text-gray-800 pacifico-regular"
          >
            <img src={Logo} className="w-[200px] h-[44px] rounded-[12px]" />
          </Link>
        </div>
        <div className="menu" id="menu">
          <Link to="/">Home</Link>
          <Link to="/products">Products</Link>
          <Link to="/custom-order">Custom Order</Link>
          <Link to="/contact"> Contact Us</Link>
          <Link to="/about">About</Link>
        </div>
        <div className="menu-icons" id="menu-icons">
          <Link to="/cart" className="text-gray-600 hover:text-gray-900">
            <FaShoppingCart size={24} />
          </Link>
          {!isAuthenticated && (
            <Link to="/login" className="text-gray-600 hover:text-gray-900">
              <FaSignInAlt size={24} />
            </Link>
          )}
          <Link to="/search" className="text-gray-600 hover:text-gray-900">
            <FaSearch size={24} />
          </Link>
        </div>
      </div>

      {/* Bottom Navigation for Small Screens */}

      <div className="bottom-nav" id="bottom-nav">
        <Link to="/">
          <HomeIcon />
          <span className="icon-text">Home</span>
        </Link>
        <Link to="/products">
          <ShoppingCartIcon />
          <span className="icon-text">Products</span>
        </Link>
        <Link to="/custom-order">
          <BuildIcon />
          <span className="icon-text">Custom Order</span>
        </Link>
        <Link to="/contact">
          <ContactMailIcon />
          <span className="icon-text">Contact Us</span>
        </Link>
        <Link to="/about">
          <InfoIcon />
          <span className="icon-text">About</span>
        </Link>
      </div>
    </div>
  );
};

export default Navbar;
