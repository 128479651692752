import {
  ALL_DESIGN_FAIL,
  ALL_DESIGN_REQUEST,
  ALL_DESIGN_SUCCESS,
  DESIGN_DETAILS_REQUEST,
  DESIGN_DETAILS_FAIL,
  DESIGN_DETAILS_SUCCESS,
  ADMIN_DESIGN_REQUEST,
  ADMIN_DESIGN_SUCCESS,
  ADMIN_DESIGN_FAIL,
  NEW_DESIGN_REQUEST,
  NEW_DESIGN_SUCCESS,
  NEW_DESIGN_FAIL,
  NEW_DESIGN_RESET,
  DELETE_DESIGN_REQUEST,
  DELETE_DESIGN_SUCCESS,
  DELETE_DESIGN_FAIL,
  DELETE_DESIGN_RESET,
  UPDATE_DESIGN_REQUEST,
  UPDATE_DESIGN_SUCCESS,
  UPDATE_DESIGN_FAIL,
  UPDATE_DESIGN_RESET,
  CLEAR_ERRORS,
} from "../constants/designConstants";

export const designsReducer = (state = { designs: [] }, action) => {
  switch (action.type) {
    case ALL_DESIGN_REQUEST:
    case ADMIN_DESIGN_REQUEST:
      return {
        loading: true,
        designs: [],
      };
    case ALL_DESIGN_SUCCESS:
      return {
        loading: false,
        designs: action.payload.designs,
        designsCount: action.payload.designsCount,
        resultPerPage: action.payload.resultPerPage,
        filteredDesignsCount: action.payload.filteredDesignsCount,
      };
    case ADMIN_DESIGN_SUCCESS:
      return {
        loading: false,
        designs: action.payload,
      };
    case ALL_DESIGN_FAIL:
    case ADMIN_DESIGN_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const designDetailsReducer = (state = { design: {} }, action) => {
  switch (action.type) {
    case DESIGN_DETAILS_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case DESIGN_DETAILS_SUCCESS:
      return {
        loading: false,
        design: action.payload,
      };
    case DESIGN_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const newDesignReducer = (state = { design: {} }, action) => {
  switch (action.type) {
    case NEW_DESIGN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case NEW_DESIGN_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        design: action.payload.design,
      };
    case NEW_DESIGN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case NEW_DESIGN_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const designReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_DESIGN_REQUEST:
    case UPDATE_DESIGN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_DESIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_DESIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_DESIGN_FAIL:
    case UPDATE_DESIGN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_DESIGN_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_DESIGN_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
