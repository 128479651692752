import React, { Fragment, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import "./productList.css";
import { useSelector, useDispatch } from "react-redux";
import {
  clearErrors,
  getAdminDesign,
  deleteDesign,
} from "../../actions/designAction.js";
import { useAlert } from "react-alert";
import { Button } from "@mui/material";
import MetaData from "../layout/MetaData";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SideBar from "./Sidebar";
import { DELETE_DESIGN_RESET } from "../../constants/designConstants";
import { useNavigate } from "react-router-dom";

const DesignsList = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const alert = useAlert();

  const { error, designs } = useSelector((state) => state.designs);

  const { error: deleteError, isDeleted } = useSelector(
    (state) => state.design
  );

  const deleteDesignHandler = (id) => {
    dispatch(deleteDesign(id));
  };

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (deleteError) {
      alert.error(deleteError);
      dispatch(clearErrors());
    }

    if (isDeleted) {
      alert.success("Design Deleted Successfully");
      navigate("/admin/dashboard");
      dispatch({ type: DELETE_DESIGN_RESET });
    }

    dispatch(getAdminDesign());
  }, [dispatch, alert, error, deleteError, navigate, isDeleted]);

  const columns = [
    { field: "id", headerName: "Design ID", minWidth: 200, flex: 0.5 },
    {
      field: "filename",
      headerName: "File Name",
      minWidth: 350,
      flex: 1,
    },
    {
      field: "category",
      headerName: "Category",
      minWidth: 350,
      flex: 1,
    },

    {
      field: "actions",
      flex: 0.3,
      headerName: "Actions",
      minWidth: 150,
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <Fragment>
            <Button onClick={() => deleteDesignHandler(params.row.id)}>
              <DeleteIcon />
            </Button>
          </Fragment>
        );
      },
    },
  ];

  const rows = [];

  designs.length > 0 &&
    designs.forEach((item) => {
      rows.push({
        id: item._id,
        category: item.category,
        filename: item.file.originalname,
      });
    });

  return (
    <Fragment>
      <MetaData title={`ALL DESIGN - Admin`} />

      <div className="dashboard mt-[84px]">
        <SideBar />
        <div className="productListContainer">
          <h1 id="productListHeading">ALL DESIGN</h1>

          <DataGrid
            rows={rows}
            columns={columns}
            disableSelectionOnClick
            className="productListTable"
            autoHeight
          />
        </div>
      </div>
    </Fragment>
  );
};

export default DesignsList;
