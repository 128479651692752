import React, { Fragment, useEffect, useRef, useState } from "react";
import CheckoutSteps from "../Cart/CheckoutSteps";
import { useDispatch, useSelector } from "react-redux";
import MetaData from "../layout/MetaData";
import "./payment.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { formatRupees } from "../../Utilities/formatRupees";

const Screenshot = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const orderInfo = JSON.parse(sessionStorage.getItem("orderInfo"));
  const { shippingInfo, cartItems } = useSelector((state) => state.cart);
  const navigate = useNavigate();
  const order = {
    shippingInfo,
    orderItems: cartItems,
    itemsPrice: orderInfo.subtotal,
    paymentInfo: {
      status: "Pending",
    },
    totalPrice: orderInfo.totalPrice,
  };

  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    if (!file) {
      alert("Please upload a file first!");
      return;
    }

    createOrder(order, file, navigate, setLoading);
  };

  const createOrder = async (order, file, navigate, setLoading) => {
    const token = localStorage.getItem("token");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token ? `Bearer ${token}` : "",
      },
    };

    try {
      // Create the order
      const { data } = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/order/new`,
        order,
        config
      );

      const orderId = data.order._id;

      // Create FormData to send the file
      const formData = new FormData();
      formData.append("screenshot", file);
      formData.append("orderId", orderId);

      // Send the file to the backend
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/order/uploadScreenshot`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Important for sending form data
            Authorization: token ? `Bearer ${token}` : "",
          },
        }
      );

      if (res.status === 200) {
        setLoading(false);
        navigate("/success");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to send email. " + error.message);
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <MetaData title="Screenshot" />
      <CheckoutSteps activeStep={3} />
      <div className="paymentContainer h-[150vh] md:h-[65vh] mt-[84px]">
        <div className="paymentForm w-[90%] md:w-[70%] flex flex-col items-center">
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col items-center justify-center gap-5 text-center">
              <h1 className="text-lg">
                Submit Screenshot of PKR {formatRupees(orderInfo.totalPrice)}{" "}
                below
              </h1>
              <div className="flex items-center justify-center ml-[30%]">
                <input type="file" onChange={handleFileChange} />
              </div>

              <button
                type="submit"
                className="paymentFormBtn w-[50vw] lg:w-[10vw] mt-5"
                disabled={loading}
              >
                {loading ? "Submitting..." : "Upload ScreenShot"}
              </button>

              {loading && <p>Preparing Order. Donot Leave or close the page</p>}
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};
export default Screenshot;
