import axios from "axios";

import {
  ALL_DESIGN_FAIL,
  ALL_DESIGN_REQUEST,
  ALL_DESIGN_SUCCESS,
  ADMIN_DESIGN_REQUEST,
  ADMIN_DESIGN_SUCCESS,
  ADMIN_DESIGN_FAIL,
  NEW_DESIGN_REQUEST,
  NEW_DESIGN_SUCCESS,
  NEW_DESIGN_FAIL,
  DELETE_DESIGN_REQUEST,
  DELETE_DESIGN_SUCCESS,
  DELETE_DESIGN_FAIL,
  UPDATE_DESIGN_REQUEST,
  UPDATE_DESIGN_SUCCESS,
  UPDATE_DESIGN_FAIL,
  CLEAR_ERRORS,
} from "../constants/designConstants.js";

export const getDesign =
  (keyword = "", currentPage = 1, category) =>
  async (dispatch) => {
    try {
      dispatch({ type: ALL_DESIGN_REQUEST });

      const link = `${process.env.REACT_APP_BACKEND_URL}/api/v1/designs`;

      const { data } = await axios.get(link);
      dispatch({
        type: ALL_DESIGN_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ALL_DESIGN_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// Delete DESIGN
export const deleteDesign = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_DESIGN_REQUEST });
    const token = localStorage.getItem("token");

    // Configuration for the request with Authorization header
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token ? `Bearer ${token}` : "",
      },
    };
    const { data } = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/design/${id}`,
      config
    );

    dispatch({
      type: DELETE_DESIGN_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_DESIGN_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Get All DESIGNs For Admin
export const getAdminDesign = () => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_DESIGN_REQUEST });
    const token = localStorage.getItem("token");

    // Configuration for the request with Authorization header
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: token ? `Bearer ${token}` : "", // Include token if it exists
      },
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/designs`,
      config
    );

    dispatch({
      type: ADMIN_DESIGN_SUCCESS,
      payload: data.designs,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_DESIGN_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Create DESIGN
export const createDesign = (designData) => async (dispatch) => {
  try {
    console.log(designData);

    dispatch({ type: NEW_DESIGN_REQUEST });

    const token = localStorage.getItem("token");
    const config = {
      headers: {
        "Content-Type": "multipart/form-data", // Important for sending form data
        Authorization: token ? `Bearer ${token}` : "",
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/design/new`,
      designData,
      config
    );

    dispatch({
      type: NEW_DESIGN_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: NEW_DESIGN_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Clearing Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
