import React from "react";
import { Link } from "react-router-dom";

const DesignCard = ({ design }) => {
  return (
    <Link
      className="productCard"
      to={`/customize`}
      state={{
        imageUrl: design.imagesLink?.[0]?.url,
        file: design.file,
        publicId: design.imagesLink?.[0]?.public_id,
      }}
    >
      <img src={design.imagesLink?.[0]?.url} alt={design.name} />
    </Link>
  );
};

export default DesignCard;
