import React, { Fragment, useEffect, useState } from "react";
import "./Products.css";
import { useSelector, useDispatch } from "react-redux";
import { clearErrors, getProduct } from "../../actions/productAction";
import Loader from "../layout/Loader/Loader";
import ProductCard from "../Home/ProductCard";
import { useParams } from "react-router-dom";
import Pagination from "react-js-pagination";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";
import { useAlert } from "react-alert";
import MetaData from "../layout/MetaData";
import Footer from "../layout/Footer/Footer";

const categories = [
  "Home Furniture Design",
  "Military Furniture Design",
  "HealthCare Furniture Design",
  "Chairs Design",
  "Sofa Designs",
  "Steel Locker Design",
  "Almirah Design",
  "Side Tables",
];

const Products = () => {
  const dispatch = useDispatch();

  const alert = useAlert();

  const [currentPage, setCurrentPage] = useState(1);
  const [price, setPrice] = useState([0, 200000]);
  const [category, setCategory] = useState("");
  const [ratings, setRatings] = useState(0);

  const {
    products,
    loading,
    error,
    productsCount,
    resultPerPage,
    filteredProductsCount,
  } = useSelector((state) => state.products);

  const { keyword } = useParams();

  const setCurrentPageNo = (e) => {
    setCurrentPage(e);
  };

  const priceHandler = (event, newPrice) => {
    setPrice(newPrice);
  };

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    dispatch(getProduct(keyword, currentPage, price, category, ratings));
  }, [dispatch, keyword, currentPage, price, category, ratings, alert, error]);

  let count = filteredProductsCount;

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <div className="h-full min-h-[calc(100dvh-272px)] mt-[84px]">
          <MetaData title="PRODUCTS -- ECOMMERCE" />
          <h2 className="productsHeading">Products</h2>

          <div className="products">
            {products &&
              products.map((product) => (
                <ProductCard key={product._id} product={product} />
              ))}
          </div>

          <hr className="h-[2px] bg-gray-600 my-6 w-full sm:hidden block" />

          <div className="filterBox">
            <Typography>
              Price ( <span>{price[0]}</span> - <span>{price[1]}</span> )
            </Typography>
            <Slider
              value={price}
              onChange={priceHandler}
              valueLabelDisplay="off"
              aria-labelledby="continuous-slider"
              min={0}
              max={200000}
              defaultValue={200000}
            />
            <Typography component="legend">
              Ratings Above ( <span>{ratings}</span> )
            </Typography>
            <Slider
              value={ratings}
              onChange={(e, newRating) => {
                setRatings(newRating);
              }}
              aria-labelledby="continuous-slider"
              valueLabelDisplay="off"
              min={0}
              max={5}
            />

            <Typography>Categories</Typography>
            <ol className="categoryBox flex flex-col gap-1 mb-4">
              {categories.map((category) => (
                <li
                  className="category-link whitespace-nowrap underline"
                  key={category}
                  onClick={() => setCategory(category)}
                >
                  {category}
                </li>
              ))}
            </ol>
          </div>

          {resultPerPage < count && (
            <div className="paginationBox">
              <Pagination
                activePage={currentPage}
                itemsCountPerPage={resultPerPage}
                totalItemsCount={productsCount}
                onChange={setCurrentPageNo}
                nextPageText="Next"
                prevPageText="Prev"
                firstPageText="1st"
                lastPageText="Last"
                itemClass="page-item"
                linkClass="page-link"
                activeClass="pageItemActive"
                activeLinkClass="pageLinkActive"
              />
            </div>
          )}
        </div>
      )}
      <Footer />
    </Fragment>
  );
};

export default Products;
