import React from "react";
import "./Footer.css";
import logo from "../../../images/Mashallah Furniture_page-0001.jpg";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import { Button, Typography, Avatar } from "@material-ui/core";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

const Footer = () => {
  return (
    <footer id="footer" className="flex flex-col md:flex-row md:gap-0 gap-10">
      <div className="leftFooter">
        <h3>Address:</h3>
        <p>R-82, Gohar Green City Malir, Karachi</p>
        <div style={{ display: "flex", marginTop: "20px" }}>
          <EmailIcon />
          <p>touqeershahid8015@gmail.com</p>
        </div>
        <div style={{ display: "flex", marginTop: "20px" }}>
          <LocalPhoneIcon />
          <p>+923097996909</p>
        </div>
      </div>

      <div className="midFooter flex items-center flex-col">
        <img src={logo} alt="logo" style={{ width: "10vmax" }} />
        <p>MashaAllah Furniture Store</p>
        <p>Copyrights 2024 &copy; Touqeer</p>
      </div>

      <div className="rightFooter">
        <h4>Follow Us</h4>
        <a href="https://www.instagram.com/fully_made_by_steel?utm_source=qr&igsh=Yjd4cWJ6amg3N24y">
          Instagram
        </a>
        <a
          href="https://www.facebook.com/profile.php?id=61563348437783&sfnsn=scwspwa&mibextid=RUbZ1f
"
        >
          Facebook
        </a>
      </div>
    </footer>
  );
};

export default Footer;
