import React from "react";
import "./aboutSection.css";
import { Button, Typography, Avatar } from "@material-ui/core";
// import Facebook from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import logo from "../../../images/Mashallah Furniture_page-0001.jpg";
import logo2 from "../../../images/logo-BLACK_page-0001.jpg";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
const About = () => {
  const visitInstagram = () => {
    window.location =
      "https://www.instagram.com/fully_made_by_steel?utm_source=qr&igsh=Yjd4cWJ6amg3N24y";
  };
  const visitFacebook = () => {
    window.location =
      "https://www.facebook.com/profile.php?id=61563348437783&sfnsn=scwspwa&mibextid=RUbZ1f";
  };
  return (
    <div className="aboutSection mt-[84px]">
      <div></div>
      <div className="aboutSectionGradient"></div>
      <div className="aboutSectionContainer">
        <Typography component="h1">About Us</Typography>

        <div className="mb-7">
          <div>
            {/* <Avatar
              style={{ width: "10vmax", height: "10vmax", margin: "2vmax 0" }}
              src="https://images.unsplash.com/photo-1631631480669-535cc43f2327?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8YmFja2dyb3VuZCUyMGltYWdlfGVufDB8fDB8fA%3D%3D&w=1000&q=80"
              alt="Founder"
            /> */}
            <img
              src={logo}
              alt="logo"
              style={{
                width: "10vmax",
                height: "10vmax",
                margin: "2vmax 0",
                borderRadius: "150px",
              }}
            />
            <Typography>MashaAllah Furniture Store</Typography>
            <div style={{ display: "flex" }}>
              <Button onClick={visitInstagram} color="primary">
                <InstagramIcon className="instagramSvgIcon" />
              </Button>
              <Button onClick={visitFacebook} color="primary">
                <FacebookIcon className="instagramSvgIcon" />
              </Button>
            </div>
            <h3>Address:</h3>
            <p>R-82, Gohar Green City Malir, Karachi</p>
            <div style={{ display: "flex", marginTop: "20px" }}>
              <EmailIcon />
              <p>touqeershahid8015@gmail.com</p>
            </div>
            <div style={{ display: "flex", marginTop: "20px" }}>
              <LocalPhoneIcon />
              <p>+923097996909</p>
            </div>
          </div>
          <div
            className="aboutSectionContainer2"
            style={{
              textAlign: "center",
              padding: "40px 20px",
              fontFamily: "'Roboto', sans-serif",
              backgroundColor: "#f9f9f9",
              borderRadius: "10px",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            }}
          >
            <h2
              style={{
                fontSize: "2.5rem",
                fontWeight: "700",
                marginBottom: "28.8px",
                color: "#333",
                letterSpacing: "0.05em",
                textTransform: "uppercase",
                backgroundColor: "#f9f9f9",
              }}
            >
              Our Vision
            </h2>

            <p
              style={{
                fontSize: "1.2rem",
                lineHeight: "1.5",
                color: "#555",
                marginBottom: "20px",
                maxWidth: "800px",
                margin: "0 auto",
                // fontStyle: "italic",
                padding: "0 20px",
              }}
            >
              "At MashaAllah Furniture, our vision is to create sleek, durable
              steel furniture that blends modern design with timeless quality.
              We aim to elevate spaces with craftsmanship that reflects
              strength, style, and innovation."
            </p>

            <a
              href="https://meec.com.pk/services/?fbclid=PAZXh0bgNhZW0CMTEAAaaGhZqWDp5liCXjjRiXH_6sZlyqOiOfCHQ_AMia2gpQZKo2VDtcrFwRaqA_aem_nT-b91iZXs4jnlD-q-VFgg"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "block",
                marginBottom: "28.8px",
                marginTop: "28.8px",
              }}
            >
              <img
                src={logo2}
                alt="MashaAllah Engineering Logo"
                style={{
                  width: "75%",
                  margin: "0 auto",
                  display: "block",
                  borderRadius: "8px",
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                }}
              />
            </a>

            <a
              href="https://meec.com.pk/services/?fbclid=PAZXh0bgNhZW0CMTEAAaaGhZqWDp5liCXjjRiXH_6sZlyqOiOfCHQ_AMia2gpQZKo2VDtcrFwRaqA_aem_nT-b91iZXs4jnlD-q-VFgg"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                color: "#fff",
                fontWeight: "bold",
                textDecoration: "none",
                padding: "12px 25px",
                backgroundColor: "#333",
                borderRadius: "5px",
                transition: "background-color 0.3s ease, box-shadow 0.3s ease",
                marginBottom: "28.8px",
                display: "inline-block",
              }}
              onMouseOver={(e) => {
                e.target.style.backgroundColor = "#555";
                e.target.style.boxShadow = "0 4px 12px rgba(0, 0, 0, 0.2)";
              }}
              onMouseOut={(e) => {
                e.target.style.backgroundColor = "#333";
                e.target.style.boxShadow = "none";
              }}
            >
              Visit Our Partner Group
            </a>

            <p
              style={{
                fontSize: "1.2rem",
                lineHeight: "1.6",
                color: "#666",
                maxWidth: "800px",
                margin: "0 auto",
              }}
            >
              MashaAllah Furniture, in partnership with MashaAllah Engineering,
              specializes in all types of steel furniture. Established in 2003,
              MashaAllah Engineering has built a strong reputation by delivering
              professional, efficient, and reliable services to multinational
              and international clients, ensuring complete satisfaction.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
