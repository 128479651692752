import React, { Fragment, useEffect, useRef } from "react";
import CheckoutSteps from "../Cart/CheckoutSteps";
import { useSelector, useDispatch } from "react-redux";
import MetaData from "../layout/MetaData";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@material-ui/core";
import { useAlert } from "react-alert";

import axios from "axios";
import "./payment.css";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import EventIcon from "@material-ui/icons/Event";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import { useNavigate } from "react-router-dom";
import EasypaisaImage from "../../images/easypaisa.jpg";
import JazzCashImage from "../../images/jazzcash.png";
import BankTransferImage from "../../images/bankTransfer.png";
import { formatRupees } from "../../Utilities/formatRupees";

const paymentMethods = [
  {
    image: EasypaisaImage,
    accountTitle: "Touqeer Shahid",
    bankName: "Easypaisa",
    accountNo: "03097996909",
  },
  {
    image: JazzCashImage,
    accountTitle: "Touqeer Shahid",
    bankName: "Jazzcash",
    accountNo: "0309-7996909",
  },
  {
    image: BankTransferImage,
    bankName: "BANK-AL-HABIB",
    accountTitle: "Touqeer Shahid",
    accountNo: "11150981024298020",
    IBAN: "PKO8BAHL1115098102429802",
  },
];

const Payment = () => {
  const orderInfo = JSON.parse(sessionStorage.getItem("orderInfo"));
  const payBtn = useRef(null);
  const { shippingInfo, cartItems } = useSelector((state) => state.cart);
  const navigate = useNavigate();

  const paymentData = {
    amount: Math.round(orderInfo.totalPrice * 100),
  };

  const order = {
    shippingInfo,
    orderItems: cartItems,
    itemsPrice: orderInfo.subtotal,
    taxPrice: orderInfo.tax,
    totalPrice: orderInfo.totalPrice,
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    navigate("/submitScreenshot");
  };

  // useEffect(() => {
  //   if (error) {
  //     alert.error(error);
  //     dispatch(clearErrors());
  //   }
  // }, [dispatch, error, alert]);

  return (
    <Fragment>
      <MetaData title="Payment mt-[84px]" />
      <CheckoutSteps activeStep={2} />
      <div className="paymentContainer h-[150vh] md:h-[65vh]">
        <form
          className="paymentForm w-[90%] md:w-[70%] flex flex-col items-center gap-4"
          onSubmit={(e) => submitHandler(e)}
        >
          <h1 className="text-lg">
            Choose a preferred payment option, finalize the transaction by
            paying PKR {formatRupees(orderInfo.totalPrice)}, and take a
            screenshot for your records.
          </h1>
          <input
            type="submit"
            value={`Continue`}
            ref={payBtn}
            className="paymentFormBtn w-[50vw] lg:w-[10vw]"
          />
          {/* Payment Options  */}
          <div className="flex flex-col md:flex-row w-full gap-5 justify-center">
            {paymentMethods.map((item, index) => {
              return (
                <Card sx={{ maxWidth: 345 }} className="w-300">
                  <CardActionArea>
                    {/* <CardMedia
                      component="img"
                      height="80"
                      width="200"
                      image={`/assets/${item.image}`}
                      alt="green iguana"
                      className="flex items-center justify-center"
                    /> */}
                    <div className="flex items-center justify-center">
                      <img src={item.image} className="h-[150px] w-[300px]" />
                    </div>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        Details
                      </Typography>

                      <Typography
                        variant="body1"
                        sx={{ color: "text.secondary" }}
                      >
                        Bank Name: {item.bankName} <br />
                        Account Title: {item.accountTitle} <br />
                        Account Number: {item.accountNo} <br />
                        {item.bankName === "BANK-AL-HABIB" && (
                          <>IBAN: {item.IBAN}</>
                        )}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              );
            })}
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export default Payment;
