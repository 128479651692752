import React, { useState, Fragment, useEffect } from "react";
import AccountTreeIcon from "@mui/icons-material/AccountTree"; // Ensure this is the correct import path for the icon
import "./CustomOrder.css";
import MetaData from "../layout/MetaData";
import axios from "axios";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"; // Import the plus icon
import Footer from "../layout/Footer/Footer.js";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import { formatRupees } from "../../Utilities/formatRupees.js";

// Add any necessary imports for styling

const CustomOrder = () => {
  console.log(process.env.REACT_APP_BACKEND_URL);
  const [category, setCategory] = useState("");
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Initialize the navigate function
  const [showChooseCategory, setShowChooseCategory] = useState(false);
  const categories = [
    "Home Furniture Design",
    "Military Furniture Design",
    "HealthCare Furniture Design",
    "Chairs Design",
    "Sofa Designs",
    "Steel Locker Design",
    "Almirah Design",
    "Side Tables",
  ];

  // Fetch products by category
  const fetchProductsByCategory = async (category) => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/v1/products-by-category`,
        {
          category, // Pass category as query parameter
        }
      );
      setProducts(response.data.data); // Assuming the response has the products in 'data.data'
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error("Error fetching products:", err.message);
    }
  };

  // Handle category change
  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;
    setCategory(selectedCategory);
    setShowChooseCategory(false);

    if (selectedCategory) {
      fetchProductsByCategory(selectedCategory);
    } else {
      setProducts([]); // Reset products if no category is selected
    }
  };

  useEffect(() => {
    const element = document.getElementById("section-1");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <div className="customOrderParentContainer mt-[84px]">
      <div className="customOrderContainer">
        <Fragment>
          <MetaData title="CUSTOM ORDER -- ECOMMERCE" />
          <h2 className="productsHeading">Custom Order</h2>
          <div className="categorySelector">
            <label htmlFor="category" className="categoryLabel">
              <AccountTreeIcon className="categoryIcon" /> Select Category for
              Customized Order
            </label>
            <select
              id="category"
              className="categoryDropdown"
              value={category}
              onChange={handleCategoryChange}
            >
              <option value="">Choose Category</option>
              {categories.map((cate) => (
                <option key={cate} value={cate}>
                  {cate}
                </option>
              ))}
            </select>
          </div>

          <div className="product-list">
            {/* Add Custom Card */}
            <div className="flex flex-col">
              <div
                className="add-custom-card"
                onClick={() => {
                  if (category) {
                    navigate("/customize"); // Navigate to the customize page
                  } else {
                    setShowChooseCategory(true);
                  }
                }}
              >
                <AddCircleOutlineIcon className="add-icon" />
                <p className="add-text">Add Custom</p>
              </div>
              {showChooseCategory && (
                <p className="text-red-500">Choose Category First !</p>
              )}
            </div>

            {loading ? (
              <p>Loading products...</p>
            ) : (
              products.map((product) => (
                <div
                  className="product-card"
                  key={product._id}
                  onClick={() => {
                    navigate(`/product/${product._id}`); // Navigate to the customize page
                  }}
                >
                  <div className="product-image-container">
                    <img
                      src={product.images?.[0]?.url}
                      alt={product.name}
                      className="product-image"
                    />
                  </div>
                  <div className="product-info">
                    <h3 className="product-name">{product.name}</h3>
                    <p className="product-price">
                      PKR {formatRupees(product.price)}
                    </p>
                    <p className="product-description">{product.description}</p>
                    <button className="product-button">Order Now</button>
                  </div>
                </div>
              ))
            )}
          </div>
        </Fragment>
      </div>
      <Footer />
    </div>
  );
};
export default CustomOrder;
