export function formatRupees(num) {
  if (!num) return;
  // Convert the number to string and split at the decimal point (if any)
  const parts = num.toString().split(".");

  // Use regex to add commas for every three digits from the right
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Return the number with commas, and append decimal part if it exists
  return parts.join(".");
}
