import React, { Fragment, useEffect } from "react";
import { BiDownArrow, CgMouse } from "react-icons/all";
import "./Home.css";
import DesignCard from "./DesignCard.js";
import MetaData from "../layout/MetaData";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../layout/Loader/Loader";
import { useAlert } from "react-alert";
import Footer from "../layout/Footer/Footer.js";
import { getDesign, clearErrors } from "../../actions/designAction.js";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
const Home = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { loading, error, designs } = useSelector((state) => state.designs);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    dispatch(getDesign());
  }, [dispatch, error, alert]);

  console.log(designs);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title="ECOMMERCE" />

          <div className="banner mt-[84px]">
            <h2
              // style={{ color: "skyblue" }}
              className="font-bold italic text-4xl md:text-6xl leading-[1.1] text-gray-100"
            >
              Welcome
              <br /> To <br />
              <span>MashaAllah Furniture Store</span>{" "}
            </h2>
            <div
              className="d-flex"
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "30px",
                marginBottom: "20px",
              }}
            >
              <p
                style={{
                  // marginTop: "1px",
                  marginLeft: "12px",
                  fontSize: "22px",
                }}
                className="italic"
              >
                Related by:
              </p>
              <a
                className="company font-semibold italic"
                style={{
                  fontSize: "25px",
                  marginLeft: "15px",
                  color: "#A99982",
                }}
                href="https://meec.com.pk/services/?fbclid=PAZXh0bgNhZW0CMTEAAaaGhZqWDp5liCXjjRiXH_6sZlyqOiOfCHQ_AMia2gpQZKo2VDtcrFwRaqA_aem_nT-b91iZXs4jnlD-q-VFgg"
                alt=""
              >
                MashaAllah Engineering Enterprises
              </a>
            </div>
            {/* <h1>MashaAllah Furniture Contracted by MashaAllah Engineering</h1> */}

            <a href="#container">
              <button className="flex gap-3 w-fit items-center justify-center">
                <BiDownArrow /> Designs
              </button>
            </a>
          </div>

          <h2 className="homeHeading">Designs</h2>

          <div
            className="container flex flex-col justify-center items-center"
            id="container"
          >
            {designs &&
              designs[0] &&
              designs[0].designs &&
              designs.map((design) => {
                return (
                  <div className="flex flex-col items-center justify-center">
                    {/* <h1 className=" font-bold text-xl text-center text-blue-600 ">
                      {design._id}
                    </h1> */}
                    <Stack direction="row" spacing={1}>
                      <Chip
                        label={design._id}
                        variant="outlined"
                        className="font-bold w-[90vw]"
                      />
                    </Stack>

                    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 w-[90vw]">
                      {design?.designs.map((d) => {
                        return <DesignCard key={d._id} design={d} />;
                      })}
                    </div>
                  </div>
                );
              })}
          </div>
        </Fragment>
      )}
      <Footer />
    </Fragment>
  );
};

export default Home;
