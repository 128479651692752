import React from "react";
import { useNavigate } from "react-router-dom";
import "./ThankYouPage.css"; // Make sure to create this CSS file

const ThankYouPage = () => {
  const navigate = useNavigate(); // To navigate back to the home or orders page

  const handleBackToHome = () => {
    navigate("/"); // Navigate back to the home page or any desired route
  };

  return (
    <div className="thank-you-container mt-[84px]">
      <div className="thank-you-card">
        <h1 className="thank-you-heading">Thank You for Your Order!</h1>
        <p className="thank-you-message">
          Your custom order has been successfully placed. Our team will review
          your customization query and get back to you shortly.
        </p>
        <p className="order-note">
          You can check the status of your order in your account. If you have
          any questions or need further assistance, please contact us.
        </p>
        <button className="back-home-button" onClick={handleBackToHome}>
          Back to Home
        </button>
      </div>
    </div>
  );
};

export default ThankYouPage;
