import React, { useEffect, useState } from "react";
import "./CustomizeOrderPage.css";
import axios from "axios"; // Import Axios
import { useLocation, useNavigate } from "react-router-dom";

const CustomizeOrderPage = () => {
  const location = useLocation();
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [material, setMaterial] = useState("");
  const [color, setColor] = useState("#000000"); // New state for color picker
  const [query, setQuery] = useState("");
  const [shippingDetails, setShippingDetails] = useState({
    name: "",
    address: "",
    city: "",
    postalCode: "",
    country: "",
    phoneNo: "",
  });

  const { imageUrl, file, publicId } = location.state || {};
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result); // Set the preview URL
      };
      reader.readAsDataURL(file);
    } else {
      setImage(null);
      setPreview(null);
    }
  };

  const handleShippingDetailsChange = (e) => {
    setShippingDetails({
      ...shippingDetails,
      [e.target.name]: e.target.value,
    });
  };

  const provinces = [
    "Azad Kashmir",
    "Balochistan",
    "Federally Administered Tribal Areas",
    "Gilgit-Baltistan",
    "Islamabad Capital Territory",
    "Khyber Pakhtunkhwa",
    "Punjab",
    "Sindh",
  ];

  const navigate = useNavigate(); // To navigate back to the home or orders page
  const [loader, setLoader] = useState(false);

  const handleSubmitOrder = async () => {
    setLoader(true);
    try {
      let response;

      if (imageUrl && file) {
        const data = {
          material: material,
          color: color,
          query: query,
          name: shippingDetails.name,
          address: shippingDetails.address,
          city: shippingDetails.city,
          pinCode: shippingDetails.postalCode,
          state: shippingDetails.province,
          phoneNo: shippingDetails.phoneNo,
          publicId: publicId,
          imageUrl: imageUrl,
        };

        // If an image and file are provided, include them in the JSON
        if (imageUrl && file) {
          data.designFile = file; // Attach the file directly (you can stringify it if needed)
        }

        const token = localStorage.getItem("token");

        response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/custom-order-design`,
          data, // Send the data as JSON
          {
            headers: {
              "Content-Type": "application/json", // Important for sending JSON data
              Authorization: token ? `Bearer ${token}` : "",
            },
          }
        );
      } else {
        const formData = new FormData();
        formData.append("image", image); // Append the uploaded image
        formData.append("material", material);
        formData.append("color", color); // Append the selected color
        formData.append("query", query);
        formData.append("name", shippingDetails.name);
        formData.append("address", shippingDetails.address);
        formData.append("city", shippingDetails.city);
        formData.append("pinCode", shippingDetails.postalCode);
        formData.append("state", shippingDetails.province);
        formData.append("phoneNo", shippingDetails.phoneNo);

        const token = localStorage.getItem("token");
        response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/v1/custom-order`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data", // Important for sending form data
              Authorization: token ? `Bearer ${token}` : "",
            },
          }
        );
      }

      if (response.status === 201) {
        setLoader(false);
        navigate("/custom-order/thank-you"); // Navigate to the thank you page
      }
    } catch (error) {
      setLoader(false);
      console.error("Error submitting order:", error);
      alert("Failed to submit order. Please try again.");
    }
  };

  useEffect(() => {
    if (imageUrl) {
      setPreview(imageUrl);
    }
  }, []);

  return (
    <div className="customize-order-container mt-[84px]">
      <h2 className="main-heading">Customize Your Order</h2>
      <div className="customize-order-content">
        {/* Image Upload and Preview */}
        <div className="image-upload-section">
          <h3 className="section-heading">Upload Product Image</h3>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            className="image-upload-input"
          />
          {preview && (
            <img
              src={preview}
              alt="Product Preview"
              className="image-preview"
            />
          )}
        </div>

        {/* Form for Material Type, Color Picker, and Query */}
        <div className="order-form-section">
          <div className="material-type-dropdown">
            <label htmlFor="materialType" className="form-label">
              Select Material Type
            </label>
            <select
              id="materialType"
              value={material}
              onChange={(e) => setMaterial(e.target.value)}
              className="material-dropdown"
            >
              <option value="">Choose Material</option>
              <option value="Stainless Steel">Stainless Steel</option>
              <option value="Carbon Steel">Carbon Steel</option>
            </select>
          </div>

          {/* Color Picker */}
          <div className="color-picker-section">
            <label htmlFor="colorPicker" className="form-label">
              Select Color
            </label>
            <input
              type="color"
              id="colorPicker"
              value={color}
              onChange={(e) => setColor(e.target.value)}
              className="color-picker-input"
            />
          </div>

          {/* Query Box */}
          <div className="query-box">
            <label htmlFor="query" className="form-label">
              Additional Queries or Details
            </label>
            <textarea
              id="query"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Enter any additional information about your custom order here..."
              className="query-textarea"
              rows={5}
            ></textarea>
          </div>

          {/* Shipping Details Form */}
          <div className="shipping-details-form">
            <h3 className="section-heading">Shipping Details</h3>
            {/* Add input fields for shipping details here */}
            <input
              type="text"
              name="name"
              placeholder="Full Name"
              value={shippingDetails.name}
              onChange={handleShippingDetailsChange}
              required
            />
            <input
              type="text"
              name="phoneNo"
              placeholder="Phone No"
              value={shippingDetails.phoneNo}
              onChange={handleShippingDetailsChange}
              required
            />
            <input
              type="text"
              name="address"
              placeholder="Address"
              value={shippingDetails.address}
              onChange={handleShippingDetailsChange}
              required
            />
            <input
              type="text"
              name="city"
              placeholder="City"
              value={shippingDetails.city}
              onChange={handleShippingDetailsChange}
              required
            />
            <input
              type="text"
              name="postalCode"
              placeholder="Postal Code"
              value={shippingDetails.postalCode}
              onChange={handleShippingDetailsChange}
              required
            />
            <select
              id="province"
              name="province"
              value={shippingDetails.province}
              onChange={handleShippingDetailsChange}
              className="material-dropdown"
              required
            >
              <option value="">Choose Province</option>
              {provinces.map((province) => (
                <option key={province} value={province}>
                  {province}
                </option>
              ))}
            </select>
          </div>

          {/* Generate Order Button */}
          <button
            className="generate-order-button"
            disabled={loader}
            onClick={handleSubmitOrder}
          >
            {loader ? "Generating Order..." : "Generate Order"}
          </button>
        </div>
      </div>

      {/* Important Notes */}
      <div className="order-notes">
        <p className="text-center md:text-left">
          <strong>Note:</strong>
        </p>
        <ul className=" text-left ">
          <li>- For customized orders, a 40% advance payment is required.</li>
          <li>- The shipping fee will be paid by the customer.</li>
          <li>- The minimum quantity of order should be 3.</li>
        </ul>
      </div>
    </div>
  );
};

export default CustomizeOrderPage;
