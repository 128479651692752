export const ALL_DESIGN_REQUEST = "ALL_DESIGN_REQUEST";
export const ALL_DESIGN_SUCCESS = "ALL_DESIGN_SUCCESS";
export const ALL_DESIGN_FAIL = "ALL_DESIGN_FAIL";

export const ADMIN_DESIGN_REQUEST = "ADMIN_DESIGN_REQUEST";
export const ADMIN_DESIGN_SUCCESS = "ADMIN_DESIGN_SUCCESS";
export const ADMIN_DESIGN_FAIL = "ADMIN_DESIGN_FAIL";

export const NEW_DESIGN_REQUEST = "NEW_DESIGN_REQUEST";
export const NEW_DESIGN_SUCCESS = "NEW_DESIGN_SUCCESS";
export const NEW_DESIGN_RESET = "NEW_DESIGN_RESET";
export const NEW_DESIGN_FAIL = "NEW_DESIGN_FAIL";

export const UPDATE_DESIGN_REQUEST = "UPDATE_DESIGN_REQUEST";
export const UPDATE_DESIGN_SUCCESS = "UPDATE_DESIGN_SUCCESS";
export const UPDATE_DESIGN_RESET = "UPDATE_DESIGN_RESET";
export const UPDATE_DESIGN_FAIL = "UPDATE_DESIGN_FAIL";

export const DELETE_DESIGN_REQUEST = "DELETE_DESIGN_REQUEST";
export const DELETE_DESIGN_SUCCESS = "DELETE_DESIGN_SUCCESS";
export const DELETE_DESIGN_RESET = "DELETE_DESIGN_RESET";
export const DELETE_DESIGN_FAIL = "DELETE_DESIGN_FAIL";

export const DESIGN_DETAILS_REQUEST = "DESIGN_DETAILS_REQUEST";
export const DESIGN_DETAILS_SUCCESS = "DESIGN_DETAILS_SUCCESS";
export const DESIGN_DETAILS_FAIL = "DESIGN_DETAILS_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
